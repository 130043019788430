class UtilsService {

  generatePrefix(locale) {
    // console.log(locale)
    if(locale === 'en') {
      return '';
    } else {
      return `/${locale}`;
    }
  }

}

export const utilsService = new UtilsService();
