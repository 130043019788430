import React from 'react'
import { Link } from 'gatsby'

import { utilsService } from "../services/utils-service";

// Assets
import MnlLogo from '../assets/images/mnl-logo.png'

export default class Menu extends React.Component {

  constructor(props) {
    super(props)
  }

  _renderMenuItems = () => {
    let r_array = []
    this.props.menu.map((item, index) => {
      let c_item = (
        <li className="menu__item"
            data-id={item.node.id}
            key={`item-${index}`}>
          <Link
            className="menu__link overline"
            exact="true"
            to={`${utilsService.generatePrefix(this.props.locale)}/${item.node.link}`}
          >
            {item.node.name}
          </Link>
        </li>
      )
      r_array.push(c_item)
    })
    return r_array
  }

  render() {
    return (
      <div className="menu">
        <nav className="row no-gutters align-items-center justify-content-between">
          {/* Logo */}
          <div className="menu__logo">
            <Link to={`${utilsService.generatePrefix(this.props.locale)}/`}>
              <img src={MnlLogo} alt="Minale Design Strategy - Logo" />
            </Link>
          </div>
          {/* Menu */}
          <ul className="menu__list flex-grow-1">
            {this._renderMenuItems()}
          </ul>
          {/* Locales */}
          <div className="menu__locales">
            {this.props.locales.map(item =>
              <Link
                to={`${utilsService.generatePrefix(item)}/${this.props.absolutePath ? this.props.absolutePath : ''}`}
                key={`locale-${item}`}
                className={`menu__locales__option ${item === this.props.locale ? 'active' : ''}`}
              >
                <p className={`paragraph bold uppcase`}>{item}</p>
              </Link>
            )}
          </div>
        </nav>
      </div>
    )
  }

}
