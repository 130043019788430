import React from 'react'

// Components
import Wrapper from '../components/wrapper'
import Menu from '../components/menu'
import MenuMobile from '../components/menu_mobile'

const Header = ({ menu, locales, locale, absolutePath, color }) => (
  <Wrapper grid>
    <div className="header">
      <Menu menu={menu} locales={locales} locale={locale} absolutePath={absolutePath} />
      <MenuMobile menu={menu} locales={locales} locale={locale} absolutePath={absolutePath} color={color} />
    </div>
  </Wrapper>
)

export default Header
