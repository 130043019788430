import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

// Components
import Wrapper from '../components/wrapper'
import Header from '../components/header'
import Footer from '../components/footer'

// Styles
import './main.scss'

const Layout = ({ children, data, path = '', headerWhite, locale, absolutePath }) => {

  return (
    <div>
      <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
      <Wrapper color={headerWhite ? '' : 'yellow'}>
        <Header
          menu={data.allDatoCmsMenu.edges.filter(el => el.node.locale === locale)}
          locales={data.datoCmsSite.locales}
          locale={locale}
          color={headerWhite ? '' : 'yellow'}
          absolutePath={absolutePath}
        />
      </Wrapper>
      <div>
        {children}
      </div>
      <Footer  {...data.allDatoCmsFooter.edges[0].node} />
    </div>
  )
}

const query = graphql`
  query layoutQuery {
    allDatoCmsMenu(
      sort: {fields: position},
    ) {
      edges {
        node {
          id
          name
          link
          locale
        }
      }
    }
    allDatoCmsFooter(filter: {
      locale: { eq: "en" }
    }) {
      edges {
        node {
          copyright
          socialList {
            id
            service
            copyName
            link
          }
        }
      }
    }
    datoCmsSite {
      locales
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`

export default ({children, path, headerWhite = false, locale, absolutePath}) => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout
        path={path}
        data={data}
        locale={locale}
        children={children}
        headerWhite={headerWhite}
        absolutePath={absolutePath}
      />
    )}
  />
)
