import React from 'react'
import { Link } from 'gatsby'
import posed from 'react-pose'

import { utilsService } from "../services/utils-service";

// Assets
import MnlLogo from '../assets/images/mnl-logo.png'

export default class MenuMobile extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      buttonTitle: "Menu",
      isMenuMobileOpen: false,
      isMenuVisible: true,
      lastScrollTop: 0,
    }
  }

  _handleMobileMenu = () => {
    this.setState(
      prevState => ({
        buttonTitle: this.state.buttonTitle === "Menu" ? "Close" : "Menu",
        isMenuMobileOpen: !this.state.isMenuMobileOpen,
      })
    )
  }

  _hideOnScroll = () => {
    const MARGIN = 20
    let currentScroll = window.pageYOffset

    if (currentScroll > 160 && currentScroll > this.state.lastScrollTop) {
      this.setState(
        prevState => ({
          isMenuVisible: false,
          lastScrollTop: currentScroll - MARGIN,
        })
      )
    } else {
      this.setState(
        prevState => ({
          isMenuVisible: true,
          lastScrollTop: currentScroll + MARGIN,
        })
      )
    }
  }

  _renderMenuItems = () => {
    let r_array = []
    this.props.menu.map((item, index) => {
      let c_item = (
        <MenuItem className="menu__item"
            data-id={item.node.id}
            key={`item-${index}`}>
          <Link
            onClick={() => this._handleMobileMenu()}
            className="menu__link link"
            exact="true"
            to={`${utilsService.generatePrefix(this.props.locale)}/${item.node.link}`}
          >
            {item.node.name}
          </Link>
        </MenuItem>
      )
      r_array.push(c_item)
    })
    return r_array
  }

  componentDidMount() {
    window.addEventListener('scroll', this._hideOnScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._hideOnScroll)
  }

  render() {
    return (
      <div
        className={!this.state.isMenuVisible ?
          `menu__mobile hidden ${this.props.color ? this.props.color : null}` :
          `menu__mobile ${this.props.color ? this.props.color : null}`
        }
      >
        <nav className="row no-gutters">
          {/* Nav */}
          <div className="menu__header align-items-center justify-content-between col-12">
            <Link
              to={`${utilsService.generatePrefix(this.props.locale)}/`}
              onClick={this.state.isMenuMobileOpen ? () => this._handleMobileMenu() : null}
            >
              <img src={MnlLogo} alt="Minale Design Strategy - Logo" />
            </Link>
            <p onClick={() => this._handleMobileMenu()} className="paragraph bold">
              {this.state.buttonTitle}
            </p>
          </div>

          {/* Menu mobile */}
          <div className={this.state.isMenuMobileOpen ? "menu__list menu__list--open" : "menu__list"}>
            <MenuList className="menu__list__items" pose={this.state.isMenuMobileOpen ? 'open' : 'closed'}>
              {this._renderMenuItems()}
            </MenuList>

            <div className="menu__list__locales">
              {this.props.locales.map(item =>
                <Link
                  to={`${utilsService.generatePrefix(item)}/${this.props.absolutePath ? this.props.absolutePath : ''}`}
                  key={`locale-mobile-${item}`}
                  className={`menu__list__locales__item ${item === this.props.locale ? 'active' : ''}`}
                  onClick={() => {
                    this._handleMobileMenu();
                  }}
                >
                  <p className={`paragraph bold uppcase`}>{item}</p>
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

const MenuList = posed.ul({
  open: {
    delayChildren: 320,
    staggerChildren: 120,
  },
  initialPose: 'closed'
})

const MenuItem = posed.li({
  open: {
    y: "0%",
    opacity: 1,
  },
  closed: {
    y: "-20%",
    opacity: 0,
  },
})
