import React from 'react'

const Wrapper = ({ children, color, border, grid, device_no_spacing }) => (
  <React.Fragment>
    {grid ? (
        <div className={device_no_spacing ?
                        "wrapper__grid device_no_spacing" :
                        "wrapper__grid"}>
          {children}
        </div>
      ) : (
        <div className={color || border ?
                        `wrapper__full ${color ? color : ""}
                         ${border ? "border" : ""}` : "wrapper__full"}>
          {children}
        </div>
      )
    }

  </React.Fragment>
)

export default Wrapper
