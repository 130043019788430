import React from 'react'

// Components
import Wrapper from '../components/wrapper'

const Footer = ({ ...props }) => (
  <Wrapper border>
    <Wrapper grid>
      <footer className="footer
                        row no-gutters
                        align-items-center">
        <div className="copyright
                        col-12 order-xs-2
                        col-lg-6">
        <p className="paragraph grey">
          {props.copyright}
        </p>
        </div>
        <div className="footer__socials
                        col-12 order-xs-1
                        col-lg-6">
          <ul>

            {props.socialList.map((item, index) => (
              // Social links
              <li data-id={item.id}
                  key={`footer__socials-${index}`}>
                <a href={item.link}
                   target="_blank"
                   rel="noreferrer"
                   className="paragraph bold link"
                   title={item.copyName}>
                  {item.service}
                </a>
              </li>
            ))}

          </ul>
        </div>
      </footer>
    </Wrapper>
  </Wrapper>
)

export default Footer
